<template>
  <div :to="{name:'currency_dash', params: {currency: item.name}}" class=" sm:mt-0 mt-0 bank_e_card bg-gray-200 rounded-lg p-4 flex flex-col justify-between overflow-hidden">
             <!-- w-220 h-134 -->
          <div class="">
            <img class=" w-12 " :src='getImgSrc(item.currency)' alt="">
          </div>
          <div>
          <div class=" font-600 text-xl text-left">{{ item.amount }}</div>
          <div class="flex  justify-between">
            <span class=" text-sm">{{item.currency}}</span>
            <div></div>
            <!-- <a class=" text-xs" href="">账户详情 >></a> -->
          </div>
          </div>
        </div>
</template>

<script>
export default {
  data () {
    return {
    }
  },
  methods: {
    getImgSrc (name) {
      return require(`@/assets/currency/${name}.png`)
    }
  },
  props: {
    item: {
      type: Object
    }
  }
}
</script>

<style scoped>
.bank_e_card{
  width: 220px;
  height: 134px;
}
@media only screen and (max-width:600px){
  .bank_e_card{
    width: 100%;
    height: auto;
  }
}
</style>
