<template>
  <div class="container max-w-2xl mx-auto">
        <div class="flex items-center space-x-2"><span class="emoji">🌍</span> 连接全球，畅通多币种账户！</div>
        <div class="flex items-center space-x-2 pb-2"> <span class="emoji">🌎</span> 欢迎来到我们的平台，您的全球支付合作伙伴！无论您是个人还是企业，无论您身在何处，我们为您提供便捷、高效的多币种账户对接服务，借助强大的技术支持。</div>
        <hr>
        <div class=" flex flex-col space-y-4 mt-4">
        <a-row>
            <a-col :span="4"><div class="highlight">多币种账户：</div></a-col>
            <a-col :span="20"><p>不再为跨国支付烦恼！通过我们的平台，您可以轻松拥有多个币种的账户，灵活管理资金，降低汇率风险。</p></a-col>
          </a-row>
        <a-row>
            <a-col :span="4"><div class="highlight">全球支付：</div></a-col>
            <a-col :span="20"><p>覆盖全球主要地区，实现快速、安全的全球支付。无论是供应商付款还是客户收款，都变得简单高效。</p></a-col>
          </a-row>
          <a-row>
            <a-col :span="4"><div class="highlight">定制化方案：</div></a-col>
            <a-col :span="20"><p>我们深知每个用户独特的需求，因此提供灵活的定制化方案。无论您是中小企业还是大型企业，我们都能为您量身打造最适合的解决方案。</p></a-col>
          </a-row>
          <a-row>
            <a-col :span="4"><div class="highlight">安全可靠：</div></a-col>
            <a-col :span="20"><p>我们以安全为首要任务。借助先进的安全技术，您的资金和交易信息始终得到最佳保障。</p></a-col>
          </a-row>
          </div>
    </div>
</template>

<script>
export default {

}
</script>

<style scoped>
.container{
  font-family: Arial, sans-serif;
  text-align: left;
  padding: 20px;
}
.emoji {
  font-size: 24px;
  margin: 0 5px;
}
.highlight {
  font-weight: bold;
  color: #007bff;
}
</style>
