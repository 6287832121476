<template>
  <div class=" w-full">
    <a-spin tip="Loading..." :spinning="pageSpinner" >
      <div class="w-full p-4">
        <div v-if="!ccAbility" class=" font-500">
            <CcDesc></CcDesc>
          <div @click="handleDash" class="text-center underline font_gold cursor-pointer">请先完成Skyline开户认证</div>
        </div>
        <div v-else>
          <div v-if="ccStatus == 'enabled'">
            <a-modal
            title="账户充值"
            :visible="fundingModal"
            :confirm-loading="confirmLoading"
            @ok="handleModalOk"
            @cancel="handleModalCancel"
          >
          <div>
            <a-spin :spinning="fundingSpin">
              <a-select
                  size="default"
                  show-search
                  :value="topUpCurrency"
                  placeholder="选择币种"
                  option-filter-prop="children"
                  @change="handleModalSelectCurrency"
                  style="width: 100%"
                  :filter-option="true"
                  :not-found-content="null"
                >
                <a-select-option v-for="item in uniqueCurrencies" :key="item.fullname" :value="item.currency">
                {{ item.currency  }}
              </a-select-option>
                </a-select>
              <div v-if="fundingData" class="">
                <div class=" text-right mt-3 text-lg">
                  <a-icon @click="handleCopy" type="copy" />
                </div>
                <div class=" grid grid-cols-2 mt-3">
                  <div class=" text-black-85 font-450">Account Holder Name</div>
                  <div class=" text-black-85 font-450">Bank Name</div>
                  <div>{{ fundingData.account_holder_name }}</div>
                  <div>{{ fundingData.bank_name }}</div>
                  <div class=" text-black-85 font-450">IBAN</div>
                  <div class=" text-black-85 font-450">Bank Address</div>
                  <div>{{ fundingData.account_number }}</div>
                  <div>{{ fundingData.bank_address }}</div>
                  <div class=" text-black-85 font-450">BIC</div>
                  <div class=" text-black-85 font-450">Bank Country</div>
                  <div>{{ fundingData.routing_code}}</div>
                  <div>{{ fundingData.bank_country}}</div>
                </div>
              </div>
              <div v-if="fundingDataError">
                {{ fundingDataError }}
              </div>
              </a-spin>
            </div>
          </a-modal>
          <!-- <CCAccountCard
          @handleAccountConvert="handleAccountConvert"
          @handleAccountTopUp="handleAccountTopUp"
          @handleAccountPayment='handleAccountPayment' :balanceArray="balance"></CCAccountCard> -->
          <newCCAccountCard v-for="item in balance" :key="item.id" :item="item"
          @handleAccountConvert="handleAccountConvert"
          @handleAccountTopUp="handleAccountTopUp"
          @handleAccountPayment='handleAccountPayment'
          ></newCCAccountCard>
          <!-- <div class="hidden">
            <div class=" flex border-b border-solid  items-center border-color  p-6 py-4 justify-between bg-white mt-6">
              <span class="text-black-85 text-lg">账户信息</span>
            </div>
            <div class="mt -flex space-y-4 flex-col  w-full p-6 flex-wrap overflow-y-auto  bg-white">
              <div class="flex items-center"><p class="mr-8  font-600 text-base">NZIE: </p>{{ customer.currency_cloud_user.reference }}</div>
              <div class="flex items-center"><p class="mr-8  font-600 text-base">Refrence: </p>{{ customer.currency_cloud_user.short_reference }}</div>
            </div>
          </div> -->
          <!-- <div class="mt-4 hidden">
            <div class=" flex border-b border-solid  items-center border-color  p-6 py-4 justify-between  bg-white">
              <span class="text-black-85 text-lg">资金余额</span>
              <a-button @click="handleTopup">
                账户充值
              </a-button>
            </div>
            <div class="flex  w-full  p-6 flex-wrap overflow-y-auto bg-white ">
              <CurencyAccountCard v-for="item in balance" class=" mr-8" :key="item.id" :item="item"></CurencyAccountCard>
            </div>
            </div> -->
          </div>
          <div v-else>
            <div>
            <CcDesc></CcDesc>
            <div class=" flex justify-center">
              <a-button v-if="ccStatus == 'pending'" :disabled="true" type="primary" @click="handleCCActive">正在审核中<span v-if="loadingDisabled">({{ countdownSeconds }} S)</span></a-button>
              <a-button v-else :disabled="cc_btn_open_disabled || loadingDisabled" type="primary" @click="handleCCActive">激活多币种账户<span v-if="loadingDisabled">({{ countdownSeconds }} S)</span></a-button>
            </div>
            </div>
          </div>
        </div>
      </div>
    </a-spin>
  </div>
</template>

<script>
import ccCountryList from '@/assets/cc/ccCountry.json'
import exchange from '@/assets/active_icons/exchange.png'
import pay from '@/assets/active_icons/pay.png'
import receive from '@/assets/active_icons/receive.png'
import withdraw from '@/assets/active_icons/withdraw.png'
import { getCCBalance, openCCAccount, getCCCFunding } from '@/api/common'
import newCCAccountCard from './newCCAccountCard.vue'
// import ProfileDashTransaction from './ProfileDashTransaction.vue'
// import CurencyAccountCard from './CurencyAccountCard.vue'
// import accountList from '../../assets/mock_data/popular_currency'
import { mapState, mapGetters, mapActions } from 'vuex'
import { message } from 'ant-design-vue'
import CcDesc from './CcDesc.vue'

export default {
  created () {
    this.ccCountryList = ccCountryList
    this.uniqueCurrencies = this.getUniqueCurrencies()
  },
  props: {
    cc_btn_open_disabled: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      topUpCurrency: null,
      fundCurrency: null,
      fundingData: null,
      fundingDataError: null,
      fundingSpin: false,
      confirmLoading: false,
      fundingModal: false,
      // accountList,
      loadingDisabled: false,
      countdownSeconds: 10,
      exchange,
      pay,
      receive,
      ccCountryList: [],
      uniqueCurrencies: [],
      withdraw
    }
  },
  methods: {
    handleCopy () {
      const inputElement = document.createElement('input')
      inputElement.value = this.toClickString()
      document.body.appendChild(inputElement)
      inputElement.select()
      document.execCommand('copy')
      document.body.removeChild(inputElement)
      this.$message.success('复制成功')
    },
    toClickString () {
      return `Account Holder Name:\n  ${this.fundingData.account_holder_name}\n  Bank Name:\n ${this.fundingData.bank_name}  IBAN:\n ${this.fundingData.account_number} Bank Address:\n ${this.fundingData.bank_address} BIC:\n ${this.fundingData.routing_code} Bank Country:\n ${this.fundingData.bank_country}`
    },
    handleDash () {
      this.$router.push('/user')
    },
    getUniqueCurrencies () {
      const uniqueCurrenciesSet = new Set()
      return this.ccCountryList.filter(option => {
        if (option.currency !== 'CNY' && !uniqueCurrenciesSet.has(option.currency)) {
          uniqueCurrenciesSet.add(option.currency)
          return true
        }
        return false
      })
    },
    handleAccountTopUp (val) {
      this.fundingModal = true
      this.topUpCurrency = val
      this.handleModalSelectCurrency(val)
    },
    handleAccountConvert (val) {
      this.$router.push({ path: '/user/convert', query: { currency: val } })
    },
    handleAccountPayment (val) {
      this.$router.push({ path: '/user/payment-create', query: { currency: val } })
    },
    handleModalChange (val) {
      console.log(val)
    },
    handleModalSelectCurrency (val) {
      if (!val) {
        return
      }
      this.fundingSpin = true
      getCCCFunding({ currency: val, customer_id: this.customer.id }).then((res) => {
        this.fundingData = res[0]
      }).catch((e) => {
        this.fundingData = null
        this.fundingDataError = e?.data?.error
      }).finally((res) => {
        this.fundingSpin = false
      })
    },
    handleModalCancel () {
      this.fundingModal = false
    },
    handleModalOk () {
      this.fundingModal = false
    },
    handleTopup () {
      this.fundingData = null
      this.fundingDataError = null
      this.fundingModal = true
    },
    countdownInterval () {
      console.log('countdownInterval')
      this.countdownSeconds = 10
      const countdownInterval = setInterval(() => {
        this.countdownSeconds--
        if (this.countdownSeconds === 0) {
          clearInterval(countdownInterval)
          this.loadingDisabled = false
          this.countdownSeconds = 10
        }
      }, 1000)
    },
    async handleCCActive () {
      if (this.loadingDisabled) {
        return
      }
      this.loadingDisabled = true

      openCCAccount({ customer_id: this.attributes.id }).then(res => {
        return this.fetchCCOpenStatus({ customer_id: this.attributes.id })
      })
        .then((res) => {
        })
        .catch((e) => {
          if (e?.data?.customer_id === 'Customer does not have origin form data') {
            message.error('请先完成Skyline开户认证')
          }
        }).finally(() => {
          this.countdownInterval()
        })
    },

    init () {
      const defaultBalance = [
        [
          {
            id: 1,
            currency: 'AUD',
            amount: '0.00'
          },
          {
            id: 2,
            currency: 'NZD',
            amount: '0.00'
          },
          {
            id: 3,
            currency: 'USD',
            amount: '0.00'
          }
        ]
      ]
      getCCBalance({ customer_id: this.attributes.id }).then(res => {
        if (res?.balance) {
          if (res.balance.length === 0) {
            this.balance = defaultBalance
          } else {
            this.balance = res.data
          }
        }
      })
    },
    getImgSrc (name) {
      return require(`@/assets/currency/${name}.png`)
    },
    ...mapActions('cc', ['fetchCCOpenStatus'])
  },
  components: {
    // ProfileDashTransaction,
    // CurencyAccountCard,
    CcDesc,
    newCCAccountCard
  },
  computed: {
    ...mapGetters('cc', ['isCCactive', 'getSpinning']),
    ...mapState('cc', ['balance', 'customer', 'pageSpinner', 'test', 'ccAbility', 'ccStatus']),
    ...mapState('login', ['attributes'])
  }
}
</script>

<style scoped>
.font_gold{
  color: #BC9A41;
}
.border_back{
  border-color: #e2e8f0;
}
.font_black{
  color: black;
}
</style>
