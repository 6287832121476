<template>
  <div class="border-b border-solid  items-center border-color  sm:px-10  px-8 py-6   bg-white mt-6  rounded-md">
    <div class="flex sm:justify-between sm:flex-row flex-col  ">
      <div>
        <div class="text-black-85 text-lg">账号详情</div>
        <!-- <a-select class="my-2" v-model="selectedValue"  style="width: 160px">
          <a-select-option  v-for="item in balanceArray" :key="item.id" :value="item.id">
            {{ item.currency  }}
          </a-select-option>
        </a-select> -->
      </div>
      <div class=" sm:mt-0 mt-4  sm:text-right text-left">
        <CurencyAccountCard :item="{name: item.currency ,currency: item.currency, amount:item.amount }"></CurencyAccountCard>
        <!-- <div class="text-black-85 text-lg">可用余额</div>
        <div class=" relative mt-2">
          <div class=" text-xl text-black-85">{{ selectOptions.amount }}</div>
          <div class="absolute_curr">{{ selectOptions.currency }}</div>
        </div> -->
      </div>
    </div>
    <hr class=" my-6">
    <div class=" flex justify-end my-2">
      <div class="mr-6 cursor-pointer underline account_btn" @click="handleAccountTopUp">充值</div>
      <div class="mr-6 cursor-pointer underline account_btn"
      @click="handleAccountPayment">支付</div>
      <div class="cursor-pointer underline account_btn" @click="handleAccountConvert">汇兑</div>
    </div>
  </div>
</template>

<script>
import CurencyAccountCard from './CurencyAccountCard.vue'

export default {
  components: {
    CurencyAccountCard
  },
  created () {
    console.log(this.item)
  },
  data () {
    return {
    }
  },
  props: {
    item: {
      type: Object,
      default: () => {
      }
    }
  },
  methods: {
    handleAccountTopUp () {
      this.$emit('handleAccountTopUp', this.item.currency)
    },
    handleAccountPayment () {
      this.$emit('handleAccountPayment', this.item.currency)
    },
    handleAccountConvert () {
      this.$emit('handleAccountConvert', this.item.currency)
    }
  },
  computed: {
  }
}
</script>

<style scoped>
.absolute_curr{
  width: 1.5rem;
  position: absolute;
  top: 0;
  right: -1.75rem;
  font-weight: 500;
  color: #BC9A41;
}
.account_btn{
  font-weight: 500;
  font-size: 1rem;
}
.account_btn:hover{
  color: #BC9A41;
  transition: all 0.5s ease-in-out;
}
@media only screen and (max-width: 600px) {
.absolute_curr{
  width: 1.5rem;
  position:static;
  top: 0;
  right: -1.5rem;
}
}
</style>
